<template>
  <v-dialog
    v-model="dialog"
    width="500"
    class="SetInputModal"
    scrollble persistent
  >
    <v-card outlined max-height="600">
      <v-card-title class="SetInputModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            {{ userInput.title }}
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="SetInputModal__content">
        <div v-for="field in userInput.fields" :key="field.name">
          <v-text-field
            v-if="field.label"
            flat
            outlined
            :label="field.label"
            v-model="outputData[field.name]"
          />
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions class="SetInputModal__actions justify-end">
        <v-btn color="primary" @click="setInput"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: workflowDetailsActions,
  mapGetters: workflowDetailsGetters,
} = createNamespacedHelpers("workflows/details");

export default {
  name: "SetInputModal",
  data() {
    return {
      userInput: {
        title: "",
        fields: [],
      },
      dialog: false,
      task_id: null,
      outputData: null,
    };
  },
  watch: {
    inputTaskQuestions(newInputs, oldInputs) {
      if (newInputs && !oldInputs) this.open();
    },
  },
  computed: {
    ...workflowDetailsGetters({
      setInputTaskLoading: "SET_INPUT_TASK_LOADING",
      inputTaskQuestions: "INPUT_TASK_QUESTIONS",
    }),
  },
  methods: {
    ...workflowDetailsActions(["setTaskInput"]),
    open() {
      const { action_settings, task_record_id } = this.inputTaskQuestions;
      this.outputData = {};
      this.userInput = action_settings.userInput;
      action_settings.userInput.fields.forEach((field) => {
        if (field.name) {
          this.outputData[field.name] = "";
        }
      });
      this.task_id = task_record_id;
      this.dialog = true;
    },
    resetValues() {
      this.outputData = null;
      this.userInput = {
        title: "",
        fields: [],
      };
    },
    close() {
      this.dialog = false;
      this.resetValues();
    },
    async setInput() {
      await this.setTaskInput({
        task_id: this.task_id,
        input: JSON.stringify(this.outputData),
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./set-input-popup";
</style>
