<template>
  <v-dialog
    v-model="modalOpen"
    :max-width="width"
    class="ActionPrompt"
    :persistent="persistent"
  >
    <v-card outlined class="ActionPrompt__card">
      <v-card-title class="ActionPrompt__title" :class="{ error: isError }">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            <p :class="{ 'white--text': isError }" class="ma-0">
              {{ header }}
            </p>
          </v-col>
          <v-col cols="auto" class="px-0">
            <v-btn icon @click="close">
              <v-icon small :color="isError ? 'white' : 'grey'">
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ActionPrompt__content">
        <p>
          {{ body }}
        </p>
      </v-card-text>

      <v-divider />

      <v-card-actions class="ActionPrompt__actions justify-end">
        <v-btn text @click="onCancel" :disabled="loading" color="grey darken-1">
          Cancel
        </v-btn>
        <v-btn
          :color="actionType"
          @click="action"
          :disabled="loading"
          :loading="loading"
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: notificationsGetters } = createNamespacedHelpers(
  'notifications'
);

export default {
  name: 'ActionPrompt',
  props: {
    header: {
      type: String,
      default: 'Action Here',
    },
    body: {
      type: String,
      default: 'Are you sure you want to perform this action?',
    },
    actionText: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    actionType: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 314,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isError() {
      return this.actionType === 'error';
    },
  },
  methods: {
    onCancel() {
      this.close();
    },
    close() {
      this.modalOpen = false;
    },
  },
  watch: {
    loading(value) {
      if (!value && !this.hasError) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './action-prompt';
</style>
